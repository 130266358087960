<template>
  <div class="page1">
    <!--面包屑-->
    <!-- <div class="breadcrumb"></div> -->
    <!--主体内容-->
    <div class="content">
      <h2 class="title">
        <div class="title_icon"></div>
        {{ isEdit ? "修改" : "添加" }}黑名单
      </h2>
      <!--搜索条件区域-->
      <div @keydown.enter.prevent="submitData">
        <el-form
          label-position="right"
          label-width="110px"
          :model="formInline"
          style="width: 300px;"
          :rules="rules"
          :ref="'form'"
        >
          <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
            <el-input
              :maxlength="8"
              v-model.trim="formInline.plateNumber"
              :change="formInline.plateNumber = formInline.plateNumber.toUpperCase()"
              placeholder="请输入车牌号"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.License_plate_color')" prop="plateColor">
            <el-select v-model.trim="formInline.plateColor" placeholder="请选择车牌颜色">
              <el-option
                :label="v.desc"
                :value="v.code"
                v-for="v in colorList"
                :key="v.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Merchant_Name')" prop="operationId">
            <el-select  v-model.trim="formInline.operationId" filterable>
              <el-option
                :label="value.operationName"
                :value="value.operationId"
                :key="value.operationId"
                v-for="value in tenantList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.remarks')" prop="mark">
            <el-input rows="4" resize="none"
                      type="textarea"
                      placeholder="请输入备注信息"
                      v-model="formInline.content"
                      maxlength="200"
                      show-word-limit></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: left;  margin-left: 8.5%; margin-top: 40px;">
          <el-button type="primary" @click="submitData" style="width: 88px;">提交</el-button>
          <el-button type="" @click="$router.go(-1)" style="width: 88px;">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {} from "@/common/js/public.js";
  export default {
    name: "blacklist-AE",
    data() {
      let checkPhone = (rule, value, callback) => {
        if (!/^\d{11}$/.test(value)) {
          callback(new Error("手机号格式不正确"));
        } else {
          return callback();
        }
      };
      let checkPlate = (rule, value, callback) => {
        let rel = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z0-9]{1}[A-Z0-9]{1}[A-Z0-9]{4,5}([A-Z0-9挂学警港澳使]{1}|应急)$/;
        if (rel.test(value)) {
          callback();
        } else {
          callback("车牌号格式不正确");
        }
      };
      return {
        isEdit: false,
        assetId: "",
        checkList: [],
        colorList: [],
        tenantList: [],
        typeList: [],
        blacklistId: '',
        formInline: {
          plateNumber: "",
          plateColor: "",
          operationId: "",
          content: ''
        },
        rules: {
          operationId: [
            {
              required: true,
              message: "请选择商户",
              trigger: "blur"
            }
          ],
          plateNumber: [
            {
              required: true,
              message: "请输入车牌号",
              trigger: "blur"
            },
            { validator: checkPlate, trigger: "blur" }
          ],
          plateColor: [
            {
              required: true,
              message: "请输入车身颜色",
              trigger: "blur"
            }
          ],
          carOwner: [
            {
              required: true,
              message: "请输入车主姓名",
              trigger: "blur"
            }
          ],
          mobile: [
            {
              required: true,
              message: "请输入手机号",
              trigger: "blur"
            },
            { validator: checkPhone, trigger: "blur" }
          ]
        }
      };
    },
    methods: {
      /* 商户名称的下拉框 */
      getTenantList() {
        this.$axios.get("/acb/2.0/operation/nameList").then(res => {
          if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
        }
      });
      },
      getColor() {
        this.$axios.get("/acb/2.0/specialplate/plateColor/list").then(res => {
          if (res.state == 0) {
          this.colorList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
        }
      });
      },
      getType() {
        this.$axios.get("/acb/2.0/specialplate/specialPlateType/list").then(res => {
          if (res.state == 0) {
          this.typeList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
        }
      });
      },
      // 获取白名单详情
      getDetail() {
        this.$axios.get("/acb/2.0/specialplate/update").then(res => {
          if (res.state == 0) {
          let {
            plateNumber,
            carOwner,
            plateColor,
            mobile,
            specialPlateType,
            operationId
          } = res.value[0];
          this.formInline = {
            plateNumber,
            carOwner,
            plateColor,
            mobile,
            specialPlateType,
            operationId
          };
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
        }
      });
      },
      submitData() {
        this.$refs["form"].validate(valid => {
          if (valid) {
            let data = {};
            let url = "";
            let method = "";
            if (this.isEdit) {
              method = "post";
              url = "/acb/2.0/blacklist/update";
              data = Object.assign({}, this.formInline, {
                blacklistId: this.blacklistId
              });
            } else {
              url = "/acb/2.0/blacklist/add";
              data = this.formInline;
              method = "post";
            }
            this.$axios[method](url, {
              data
            }).then(res => {
              if (res.state == 0) {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                }).then(() => {
                  this.$router.go(-1);
              });
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine')
              });
            }
          });
          } else {
            // console.log("error submit!!");
        return false;
      }
      });
      }
    },
    components: {},
    created() {
      this.getColor();
      this.getTenantList();
      this.getType();
      if (this.$route.query.blacklistId) {
        // console.log(this.$route.query);
        this.isEdit = true;
        this.blacklistId = this.$route.query.blacklistId;
        this.operationId = this.$route.query.operationId;
        this.managerId = this.$route.query.managerId;
        let { plateNumber, operationId, carOwner, plateColor, content } = this.$route.query;
        plateColor = parseInt(plateColor)
        plateNumber = plateNumber.substring((plateNumber.indexOf(':')) + 1, plateNumber.length)
        this.formInline = { plateNumber, operationId, carOwner, plateColor, content };
        if (!this.$route.query.specialPlateType) {
          this.formInline.specialPlateType = -1;
        } else {
          this.$set(this.formInline, "specialPlateType", parseInt(this.$route.query.specialPlateType));
        }
      }
    },
    mounted() {},
    computed: {}
  };
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
  .breadcrumb
    height 35px
  .content
    background #FFFFFF
    overflow hidden
    border 1px solid #C0CCDA
    border-radius 4px
    padding 20px
</style>
